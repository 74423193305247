/**
 * Agora meeting types
 */
export enum AgoraWindowAction {
	CREATE = "create",
	JOIN = "join",
}

/**
 * Agora meeting redux state
 */
export interface AgoraState {
	isAgoraWindowOpen: boolean;
	windowAction: AgoraWindowAction;
	createdMeetingLink: string;
	roomId: string;
	joinedTime: number;
	leaveTime: number;
	isInvitationDialogOpen: boolean;
	invitationLink: string;
	invitationCreator: string;
}
