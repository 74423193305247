import {AppConfig} from "app/AppConfig";
import {AgoraWindowAction} from "../redux/types";
import {AuthUser} from "../../auth/authTypes";

/**
 * Agora meeting sub url for create, join meeting room
 * TODO: Move to goalie-js after sync with app and Niceday Agora app builder
 */
export enum AgoraMeetingSubURL {
	CREATE = "create/",
	JOIN = "join/",
}

/**
 * Get Agora meeting url to be rendered in iframe
 */
export function getAgoraMeetingUrl(
	meetingAction: AgoraWindowAction,
	localUser: AuthUser,
	isVideoEnabled: boolean,
	remoteUserId?: number,
	roomId?: string,
): string {
	if (!meetingAction || !localUser || isVideoEnabled === undefined) {
		return;
	}

	let subUrl: string = "";
	const localNameParam: string = `local_name=${localUser?.fullName}`;

	switch (meetingAction) {
		case AgoraWindowAction.CREATE:
			/**
			 * Example URL to be created
			 * https://meeting-ag.vercel.app/create/NDRoom?public_remote_user_id=112233&local_name=John
			 */
			const roomName: string = "NiceDay Meeting";
			const remoteUserIdParam: string = `public_remote_user_id=${remoteUserId}`;
			const callTypeParam: string = `call_type=${isVideoEnabled ? "video" : "audio"}`;
			const queryParams: string = [remoteUserIdParam, localNameParam, callTypeParam].join("&");
			const roomNameAndParams: string = [roomName, queryParams].join("?");
			subUrl = [AgoraMeetingSubURL.CREATE, roomNameAndParams].join("");
			break;

		case AgoraWindowAction.JOIN:
			/**
			 * Example URL to be created
			 * https://meeting-ag.vercel.app/join/b8a08ced-3f7c-4330-bfd2-714a81f34832?local_name=John
			 */
			const params = [roomId, localNameParam].join("?");
			subUrl = [AgoraMeetingSubURL.JOIN, params].join("");
			break;
	}

	return [AppConfig.AGORA_BASE_URL, subUrl].join("");
}

/**
 * Extract roomId from message text for join meeting room
 */
export const extractRoomIdFromText = (text: string): string => {
	if (!text) {
		return;
	}

	const matches = text?.match(/\bhttps?:\/\/\S+/gi);
	const splittedQuery: string[] = matches?.toString().split("?");

	return splittedQuery[0]?.toString().split("/").pop();
};
